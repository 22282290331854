<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-12">
        <h4 style="text-align: left">Select Your Preferred Conference Room</h4>
      </div>
      <div class="row">
        <div class="col-md-6" v-for="(room, index) in rooms" :key="index">
          <div class="card mb-3">
            <img class="card-img-top" :src="room.image_url" alt="Room Image">
            <div class="card-body">
              <h6 class="card-title"><span class="float-left" style="font-size: 20px;">{{ room.name }} (Max Capacity - {{ room.capacity }})</span><router-link :to="{ name: 'roomBookingCreate', params: { room_id: room.id } }"  tag="a"><span class="float-right text-muted" style="color: black; font-size: 20px;">Click here for room booking <i class="fa fa-arrow-right" aria-hidden="true"></i></span></router-link></h6>
<!--                <span class="float-right text-muted">Click here <i class="fa fa-arrow-right" aria-hidden="true"></i></span></h6>-->
<!--              <p class="card-text text-center mt-3"><small class="text-muted">Check Availability</small></p>-->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <a-skeleton active :loading="loading"></a-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'List',
  data() {
    return {
      rooms: [],
      loading: false,
    }
  },
  mounted() {
    this.getRooms()
  },
  methods: {
    getRooms() {
      this.loading = true
      apiClient.get('api/fetch/rooms')
        .then(response => {
          this.loading = false
          this.rooms = response.data.rooms
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.small, small {
  font-size: 90% !important;
  font-weight: 400;
}
</style>
